<template>
    <div class="header">
        <div class="section_header m-0">
            <button class="close_btn" @click="closeModal()"><i class="fas fa-long-arrow-alt-right"></i></button>
            <h2>Preview</h2>
            <label for="preview_notification2" class="switch_option capsule_btn p-0 border-0">
                <h5 class="mr-3">Full View</h5>
                <input type="checkbox" id="preview_notification2" v-model="fullView" hidden>
                <div><span></span></div>
            </label>
        </div>
    </div>
    <ul class="alt_prev" v-if="isEmail && isSms">
        <li @click="notificType = 'email'" :class="{'active' : notificType == 'email'}" v-if="isEmail">E-mail</li>
        <li @click="notificType = 'sms'" :class="{'active' : notificType == 'sms'}" v-if="isSms">SMS</li>
    </ul>
    <div class="cell">
        <div class="content_area" v-if="checkEmailPreview()">
            <email-preview :email="email" />
        </div>
        <div class="content_area" v-if="checkSmsPreview()">
            <div class="sms_preview">
                <p v-html="sms"></p>
            </div>
        </div>
    </div>
</template>

<script>
    import EmailPreview from '@/components/EmailPreview'

    export default {
        Name: 'Notification Preview',

        data () {
            return {
                preview: false,
                fullView: false,
                notificType: 'email',
                activePreview: '',
            }
        },

        props: {
            modelValue: Boolean,
            isEmail: Boolean,
            isSms: Boolean,
            email: Object,
            sms: String,
            fullPreview: Function,
        },

        emits: ['update:modelValue'],

        components: {
            EmailPreview,
        },

        watch: {
            fullView (val) {
                if (val) {
                    const vm = this;

                    vm.fullPreview(val);

                    setTimeout(function () {
                        vm.fullView = false;
                    }, 1000);
                }
            }
        },

        methods: {
            closeModal () {
                const vm = this;

                vm.$emit('update:modelValue', false);
            },

            checkEmailPreview () {
                const vm = this;

                if (vm.notificType == 'email' && vm.isEmail) {
                    vm.activePreview = 'email';
                    return true;
                } else if (vm.notificType == 'sms' && !vm.isSms) {
                    vm.activePreview = 'email';
                    return true;
                } else {
                    return false;
                }
            },

            checkSmsPreview () {
                const vm = this;

                if (vm.notificType == 'sms' && vm.isSms) {
                    vm.activePreview = 'sms';
                    return true;
                } else if (vm.notificType == 'email' && !vm.isEmail) {
                    vm.activePreview = 'sms';
                    return true;
                } else {
                    return false;
                }
            },
        },
    }
</script>

<style scoped>
    .preview_content .close_btn {
        position: absolute;
        left: -35px;
        top: 5px;
    }

    .preview_content .cell .content_area {
        height: 510px;
        padding: 0;
        border: 1px solid #e7e7e7;
        background: #fafafb;
        position: relative;
        display: flex;
        flex-direction: column;
    }

    .msg_preview {
        padding: 30px;
        background: #fff;
        border: 1px solid #eaeaea;
        border-radius: 10px;
        max-width: 800px;
        width: 100%;
        margin-bottom: 20px;
        position: relative;
    }

    .msg_preview:after {
        position: absolute;
        content: '';
        background-image: url("data:image/svg+xml,%3Csvg id='Layer_1' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 262.83 148.44'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:%23fff;stroke:%23c7c6c6;stroke-miterlimit:10;stroke-width:7px;%7D%3C/style%3E%3C/defs%3E%3Cpolyline class='cls-1' points='2.58 2.36 131.41 143.25 260.24 2.36'/%3E%3C/svg%3E");
        background-repeat: no-repeat;
        text-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
        width: 20px;
        height: 13px;
        left: calc(50% - 10px);
        top: 100%;
    }

    .msg_preview p {
        font-size: 15px;
        line-height: 22px;
        margin-bottom: 10px;
        font-weight: 400;
        color: #5a5a5a;
    }

    .tab .msg_preview p{
        font-size: 14px;
        line-height: 20px;
    }

    .desk .msg_preview h2 {
        font-size: 17px;
        line-height: 22px;
        margin-bottom: 25px;
        font-weight: 500;
        color: #121525;
    }

    .tab .msg_preview h2 {
        font-size: 15px;
        line-height: 20px;
        margin-bottom: 20px;
        font-weight: 500;
        color: #121525;
    }

    .cell .msg_preview {
        padding: 20px;
        background: #fff;
        border: 1px solid #eaeaea;
        margin: 15px;
        position: relative;
        width: auto;
    }

    .cell .msg_preview:after, .cell .sms_preview::after {
        position: absolute;
        content: '';
        background-image: url("data:image/svg+xml,%3Csvg id='Layer_1' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 262.83 148.44'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:%23fff;stroke:%23c7c6c6;stroke-miterlimit:10;stroke-width:7px;%7D%3C/style%3E%3C/defs%3E%3Cpolyline class='cls-1' points='2.58 2.36 131.41 143.25 260.24 2.36'/%3E%3C/svg%3E");
        background-repeat: no-repeat;
        text-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
        width: 20px;
        height: 13px;
        left: calc(50% - 10px);
        top: 100%;
    }

    .cell .msg_preview h2 {
        font-size: 13px;
        line-height: 18px;
        margin-bottom: 15px;
        font-weight: 500;
        color: #121525;
    }

    .cell .msg_preview p, .cell .sms_preview p {
        font-size: 16px;
        line-height: 32px;
        margin-bottom: 10px;
        font-weight: 400;
        color: #5a5a5a;
    }

    .cell .msg_preview p span {
        font-size: 16px;
        line-height: 32px;
        padding: 0;
        font-weight: 500;
        color: #121525;
    }

    .sender_info {
        display: flex;
        padding-bottom: 5px;
        margin-bottom: 15px;
        /* border-bottom: 1px solid #eaeaea; */
    }

    .sender_info img {
        width: 35px;
        height: 35px;
        margin-right: 10px;
    }

    .sender_info .info {
        margin: 0;
    }

    .sender_info .info h5 {
        font-size: 13px;
        line-height: 15px;
        font-weight: 500;
        color: #2f7eed;
        margin-bottom: 3px;
    }

    .sender_info .info h6 {
        font-size: 11px;
        line-height: 13px;
        font-weight: 500;
        color: #5a5a5a;
        margin-bottom: 3px;
    }

    .sender_info .info h6 span {
        color: #121525;
        font-weight: 600;
        padding-right: 5px;
    }

    .msgFooter p {
        font-size: 13px;
        line-height: 21px;
    }

    .cell .msgFooter p {
        font-size: 11px;
        line-height: 17px;
    }

    .preview_content .cell .content_area {
        height: 510px;
        padding: 0;
        border: 1px solid #e7e7e7;
        background: #fafafb;
        position: relative;
        display: flex;
        flex-direction: column;
    }

    .cell .sms_preview {
        padding: 15px 20px;
        background: #fff;
        border: 1px solid #eaeaea;
        margin: 15px;
        position: relative;
        border-radius: 10px;
        font-size: 12px;
        line-height: 17px;
        color: #5a5a5a;
    }

    .cell .sms_preview:after {
        right: 30px;
        left: auto;
    }

    .preview_content {
        position: relative;
        background: #fff;
        transition: all 0.3s ease-in-out;
        z-index: 2;
        margin-right: -40%;
    }

    .preview_content.show {
        margin-right: 0;
    }

    .preview_content .cell {
        width: 360px;
        padding: 60px 16px 80px;
        border-radius: 40px;
        box-shadow: 0 0 15px 1px rgba(0,0,0,0.2);
        position: relative;
        margin: 30px auto;
    }

    .preview_content .cell:after, .preview_content .cell:before {
        position: absolute;
        content: "";
        width: 50px;
        background: #f2f2f2;
        left: 50%;
        transform: translateX(-50%);
    }

    .preview_content .cell:before {
        height: 7px;
        border: 1px solid #d9d9d9;
        border-radius: 4px;
        top: 25px;
    }

    .preview_content .cell:after {
        height: 50px;
        border: 1px solid #d9d9d9;
        border-radius: 50%;
        bottom: 15px;
    }

    .preview_content .cell .content_area {
        height: 510px;
        padding: 0;
        border: 1px solid #e7e7e7;
        background: #fafafb;
        position: relative;
        display: flex;
        flex-direction: column;
    }

    .preview_content .close_btn {
        position: absolute;
        left: -35px;
        top: 5px;
    }

    .area_body.desk .dashboard {
        align-items: center;
        background: #fafafb;
    }

    .area_body.tab .dashboard, .area_body.cell .dashboard {
        background: #fafafb;
    }
</style>
